import {createSelector} from "reselect";

export const state = (rootState) => rootState.cards;

export const loading = createSelector(
    [state],
    cards => cards.loading,
)

export const error = createSelector(
    [state],
    cards => cards.error,
)

export const items = createSelector(
    [state],
    cards => cards.items,
);

export const currentCard = createSelector(
    [state],
    cards => cards.currentCard,
);
