import { Switch, Route, Redirect } from 'react-router-dom'
import { ProtectedRoute } from 'hoc'

import { LoginPage } from '../LoginPage'
import { HomeStack } from '../HomeStack'
import { memo } from 'react'
export const Root = memo(() => (
	<Switch>
		<Route exact path='/login' component={LoginPage} />
		<ProtectedRoute path='/' component={HomeStack} />
		<Route render={() => <Redirect to='/login' />} />
	</Switch>
))
