import firebase from 'firebase'
import 'firebase/messaging'


export const swDev = () => {
	var isSafari =
		/constructor/i.test(window.HTMLElement) ||
		(function (p) {
			return p.toString() === '[object SafariRemoteNotification]'
		})(
			!window['safari'] ||
				(typeof safari !== 'undefined' &&
					window.safari.pushNotification)
		)

	if (!isSafari) {
		const config = {
			apiKey: 'AIzaSyBAO1yVadWgtOJ3Ecfz8FnWSy51DBtuwvw',
			authDomain: 'ipa3-200d2.firebaseapp.com',
			projectId: 'ipa3-200d2',
			storageBucket: 'ipa3-200d2.appspot.com',
			messagingSenderId: '441862002472',
			appId: '1:441862002472:web:c06e7a591b559606dde21d',
			measurementId: 'G-WTT42TD64Y',
		}

		firebase.initializeApp(config)

		const messaging = firebase.messaging()

		const formData = new FormData()

		messaging
			.requestPermission()
			.then(() => {
				console.log('Have permission')
				return messaging.getToken()
			})
			.then((token) => {
				console.log(token)
				if (token) {
					formData.append('token', token)
					formData.append('browser', 'chrome')

					fetch('https://ipa3.testdlab.com/api/v1/notify/create', {
						method: 'POST',
						body: formData,
						headers: {
							Authorization: `Bearer ${localStorage.getItem(
								'token'
							)}`,
						},
					})
						.then(() => {
							console.log('Sended')
						})
						.catch((e) => {
							console.log('Error: ', e)
						})
				}
			})
			.catch((e) => {
				console.error('Not have permission')
			})

		messaging.onMessage((payload) => {
			console.log('onMessage: ', payload)
		})
	}
}
