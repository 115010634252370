const initialToken = localStorage.getItem("token") || null;

export const initialState = {
    loading: false,
    isAuthorized: Boolean(initialToken),
    name: null,
    email: null,
    token: initialToken,
    error: null,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH/LOGIN_INIT': return {
            ...state,
            error: null,
            loading: true,
        }
        case 'AUTH/LOGIN_DONE': return {
            ...state,
            loading: false,
            isAuthorized: true,
            token: action.payload.data.token,
            ...action.payload,
        };
        case 'AUTH/LOGIN_FAIL': return {
            ...state,
            loading: false,
            error: action.error,
        }
        case 'AUTH/CHECK_INIT': return {
            ...state,
            error: null,
            loading: true,
        }
        case 'AUTH/CHECK_DONE': return {
            ...state,
            loading: false,
            isAuthorized: true,
            ...action.payload.data,
        };
        case 'AUTH/CHECK_FAIL': return {
            ...state,
            loading: false,
            isAuthorized: false,
            error: action.error,
        }
        case 'AUTH/LOGOUT': return {
            ...initialState,
            token: null,
            isAuthorized: false,
            error: action.error,
        };
        default:
            return state;
    }
}
