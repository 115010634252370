import { createStructuredSelector } from 'reselect'
import { selectors, actions } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'

const rootSelector = createStructuredSelector({
	email: selectors.auth.email,
	loading: selectors.session.loading,
	spaces: selectors.session.spaces,
	currentSpaceId: selectors.session.currentSpaceId,
	currentSpace: selectors.session.getCurrentSpace,
	isInitialized: selectors.session.isInitialized,
	name: selectors.auth.name,
})
export const Header = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { email, spaces, name } = useSelector(rootSelector)

	const logout = useCallback(() => {
		dispatch(actions.auth.logout())
	}, [dispatch])

	const onChange = useCallback(
		(space) => {
			if (space.value === 0) {
				history.push(`/`)
			} else {
				history.push(`/${space.value}`)
			}
		},
		[history]
	)

	let options = []
	options.push({ value: 0, label: 'All spaces' })
	spaces.forEach((space) => {
		options.push({ value: space.id, label: space.name })
	})

	return (
		<nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex '>
			<div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 navbar-menu-wrapper d-flex align-items-stretch justify-content-end'>
				<ul className='navbar-nav mr-lg-2'>
					<div className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
						<Link
							className='navbar-brand brand-logo mr-5'
							to='/'
							style={{
								color: '#b1b1b5',
								marginLeft: '38px',
								textDecoration: 'none',
							}}
						>
							<img
								src='/dankotest.png'
								alt='Logo'
								className='header-logo'
							/>
						</Link>
					</div>
				</ul>
				<ul className='navbar-nav mr-lg-2' style={{ width: '100%' }}>
					<li
						className='nav-item nav-search '
						style={{ width: '100%', marginLeft: 3 }}
					>
						<div className='input-group'>
							<Select
								placeholder='Spaces'
								options={options}
								onChange={onChange}
							/>
						</div>
					</li>
				</ul>
				<ul className='navbar-nav navbar-nav-right '>
					<li className='nav-item nav-profile'>
						<Dropdown alignRight>
							<Dropdown.Toggle
								className='nav-link count-indicator '
								style={{
									color: '#b1b1b5',
									fontFamily: "'Roboto', sans-serif",
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<span style={{ marginBottom: 5 }}>{email}</span>
								<span>{name}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className='preview-list navbar-dropdown col'>
								<Dropdown.Item
									className='dropdown-item preview-item'
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<div
										className='d-flex align-items-center'
										style={{ marginBottom: 20 }}
									>
										<Link
											to='/'
											style={{
												color: '#b1b1b5',
												textDecoration: 'none',
											}}
										>
											<i
												className='typcn typcn-home'
												style={{
													color: '#248afd',
													fontSize: '17px',
													textDecoration: 'none',
												}}
											/>
											<span className='pl-2'>Home</span>
										</Link>
									</div>
									<div
										className='d-flex align-items-center'
										onClick={logout}
									>
										<i className='ti-power-off text-primary'></i>
										<span className='pl-2'>Logout</span>
									</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</li>
					<li>
						<button
							className='notify-btn btn btn-primary'
							onClick={() => {
								history.push('/notify')
							}}
						>
							Manage notifications
						</button>
					</li>
				</ul>
			</div>
		</nav>
	)
}
