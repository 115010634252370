import { takeEvery, put } from 'redux-saga/effects'
import {actions} from "store";

function* loading() {
    yield put(actions.ui.log('loading...'));
}

function* log(action) {
   yield console.log('[LOG]' , action.payload);
}

export function* logSaga() {
    yield takeEvery('UI/LOG', log);
}

export function* loadingSaga() {
    yield takeEvery('UI/LOADING', loading);
}