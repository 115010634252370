import { Link, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { createStructuredSelector } from 'reselect'
import { selectors, actions } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'hooks'
import Modal from 'components/ui/Modal'
import qr from 'assets/icons/qr-code.svg'
import download from 'assets/icons/download.svg'

const rootSelector = createStructuredSelector({
	isInitialized: selectors.session.isInitialized,
	cards: selectors.cards.items,
	loading: selectors.cards.loading,
	types: selectors.session.types,
	name: selectors.auth.name,
})

export const SpacePage = () => {
	const dispatch = useDispatch()
	const { isInitialized, cards, loading, types } = useSelector(rootSelector)
	const [show, setShow] = useState(false)
	const [curCard, setCurCard] = useState()
	const { query, set } = useQuery()
	const selectedTypes = useMemo(
		() => JSON.parse(query.get('type') || '[]'),
		[query.get('type')]
	)

	const { spaces } = useParams()
	const onSelectType = useCallback(
		(type) => {
			const arrOfTypes = []
			if (type !== null) {
				type.forEach((item) => {
					arrOfTypes.push(item.value)
				})
			}
			localStorage.setItem('types', JSON.stringify(arrOfTypes))
			set('type', JSON.stringify(arrOfTypes))
		},
		[set]
	)

	useEffect(() => {
		if (isInitialized) {
			dispatch(
				actions.cards.getCards.init({
					spaces,
					type:
						JSON.parse(localStorage.getItem('types')) ||
						selectedTypes,
				})
			)
		}
	}, [isInitialized, spaces, selectedTypes, dispatch])

	return (
		<div className='spacePage-container' style={{ width: '100%' }}>
			<div
				className='product-nav-wrapper row'
				style={{ marginBottom: ' 1.437rem' }}
			>
				<div className='col-lg-4 col-md-5 upload-btn'>
					<Link to='/upload'>
						<button
							type='button'
							className='btn btn-outline-danger btn-icon-text'
						>
							<i className='ti-upload btn-icon-prepend'></i>
							Upload file
						</button>
					</Link>
				</div>
				<div
					className='col-lg-8 col-md-7 product-filter-options'
					style={{ justifyContent: 'flex-end' }}
				>
					<Select
						isMulti={true}
						placeholder='Types'
						defaultValue={
							localStorage.getItem('types')
								? JSON.parse(localStorage.getItem('types')).map(
										(item) => {
											return { value: item, label: item }
										}
								  )
								: []
						}
						options={Object.values(types).map((type) => {
							return { value: type, label: type }
						})}
						onChange={onSelectType}
					/>
				</div>
			</div>
			<div
				className='row product-item-wrapper'
				style={{ display: 'flex', justifyContent: 'flex-start' }}
			>
				<Modal
					currentCard={curCard}
					show={show}
					onClose={() => setShow((show) => !show)}
				/>
				{loading ? (
					<div className='dot-opacity-loader'>
						<span></span>
						<span></span>
						<span></span>
					</div>
				) : (
					cards.map((card) => (
						<div
							className='col-lg-3 col-md-4 col-sm-6 col-12 product-item stretch-card'
							key={card.id}
						>
							<div
								className='card'
								style={{ borderRadius: '10px' }}
							>
								<Link
									to={
										spaces !== undefined
											? `/${spaces}/${card.id}`
											: `/${card.spaces_id}/${card.id}`
									}
									style={{
										color: '#b1b1b5',
										textDecoration: 'none',
									}}
								>
									<div
										className='card-body card-head-title'
										style={{ paddingBottom: 0 }}
									>
										<p
											className='product-title'
											style={{ marginBottom: '-5px' }}
										>
											{card.name}
										</p>
										<p className='product-description'>
											{card.bundle_id}
										</p>
									</div>
									<div
										className='cards-wrapper'
										style={{
											alignItems: 'center',
											flexDirection: 'column',
										}}
									>
										<div
											className='card-body card-body-middle-container'
											style={{ display: 'flex' }}
										>
											<div
												className='card-body img-container'
												style={{
													maxWidth: 180,
													maxHeight: 180,
													minHeight: 180,
													minWidth: 180,
													padding: 10,
												}}
											>
												<img
													alt='card'
													className='product-img-outer'
													src={card.image}
													style={{
														width: '100%',
														borderRadius: '10px',
														minWidth: 100,
													}}
												/>
											</div>
											<div
												className='card-body'
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<p>
													Version: {card.version} (
													{card.bundle_version ||
														'No bundle version'}
													)
												</p>
												<p>{card.created_at}</p>
												<p>Space: {card.space_name}</p>
												<p>Type: {card.type}</p>
											</div>
										</div>
										<div className='btn-container'>
											<a
												href={card.download}
												className='download-btn card-btn'
												onClick={(e) =>
													e.stopPropagation()
												}
												target='_blank'
												rel='noreferrer'
												style={{ marginBottom: '20px' }}
											>
												<img
													src={download}
													alt='dwn icon'
												/>
											</a>
											<a
												href='/'
												onClick={(e) => {
													setCurCard(card)
													e.preventDefault()
													e.stopPropagation()
													setShow((show) => !show)
												}}
												className='download-btn card-btn qr-btn'
												style={{ marginBottom: '20px' }}
											>
												<img src={qr} alt='qr icon' />
											</a>
										</div>
									</div>
								</Link>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	)
}
