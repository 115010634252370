import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { createStructuredSelector } from 'reselect'
import Modal from 'components/ui/Modal'
import CardModal from 'components/ui/CardModal'
import Select from 'react-select'
import bin from 'assets/icons/bin.svg'
import qr from 'assets/icons/qr-code.svg'
import download from 'assets/icons/download.svg'
import GoBack from 'components/ui/GoBack'

const rootSelector = createStructuredSelector({
	isInitialized: selectors.session.isInitialized,
	currentCard: selectors.cards.currentCard,
	loading: selectors.cards.loading,
	session: selectors.session.spaces,
	main_page_redirect: selectors.deleteBuild.main_page_redirect,
	deleteLoading: selectors.deleteBuild.deleteLoading,
	finishedLoad: selectors.deleteBuild.finishedLoad,
})

export const CardPage = () => {
	const { cardId, spaces } = useParams()
	const dispatch = useDispatch()
	const { currentCard, loading, session } = useSelector(rootSelector)
	const [selectValue, setSelectValue] = useState('')
	const [show, setShow] = useState(false)
	const [showCardModal, setShowCardModal] = useState(false)
	const [curCard, setCurCard] = useState(false)

	const onChange = (e) => {
		e.value === 0
			? window.history.pushState(null, null, `/${spaces}/${cardId}`)
			: window.history.pushState(null, null, `?version=${e.value}`)
		setSelectValue(e.value)
		dispatch(actions.cards.getById.init([cardId, e.value]))
	}

	const versions = currentCard ? currentCard.app_versions : null
	let options = []
	options.push({ value: 0, label: 'All versions' })
	if (versions !== null) {
		versions
			.sort((a, b) => b - a)
			.forEach((item) => {
				options.push({ value: item, label: item })
			})
	}

	let currentIsAdmin

	session.forEach((item) => {
		if (Number(item.id) === Number(spaces) && item.is_admin === 1) {
			currentIsAdmin = 1
		}
	})

	useEffect(() => {
		dispatch(actions.cards.getById.init(cardId))
	}, [cardId, dispatch])

	return (
		<div>
			<GoBack />
			{loading || !currentCard ? (
				''
			) : (
				<div className='card-title'>
					<p
						className='product-title'
						style={{ marginBottom: '-5px', fontSize: '20px' }}
					>
						{currentCard.builds.name}
					</p>
					<p style={{ fontSize: '20px' }}>
						{currentCard.builds.bundle_id}
					</p>
				</div>
			)}
			<div
				className='col-lg-8 col-md-7 product-filter-options versions-select'
				style={{
					justifyContent: 'flex-end',
					marginBottom: 20,
					padding: '0px',
				}}
			>
				{!currentCard ? (
					''
				) : (
					<Select
						placeholder='Versions'
						options={options}
						onChange={onChange}
					/>
				)}
			</div>
			<div className='row' style={{ color: '#b1b1b5' }}>
				<CardModal
					showCardModal={showCardModal}
					currentCard={curCard}
					onClose={() => {
						setShowCardModal((showCardModal) => !showCardModal)
					}}
				/>
				<Modal
					currentCard={curCard}
					show={show}
					onClose={() => setShow((show) => !show)}
				/>
				<div className='col-md-12'>
					<div className='card'>
						<div className='card-body'>
							<div
								className='row product-item-wrapper'
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
								}}
							>
								{loading || !currentCard ? (
									<div className='dot-opacity-loader'>
										<span></span>
										<span></span>
										<span></span>
									</div>
								) : (
									<>
										{selectValue === '' ||
										selectValue === 0 ? (
											<>
												{Object.values(
													currentCard.builds
												).map((cur) => (
													<div
														className='col-lg-3 col-md-4 col-sm-6 col-12 product-item stretch-card'
														key={cur.build_id}
														style={{
															display: 'flex',
															flexWrap: 'wrap',
															cursor: 'pointer',
														}}
														onClick={(e) => {
															e.preventDefault()
															e.stopPropagation()
															setShowCardModal(
																(
																	showCardModal
																) =>
																	!showCardModal
															)
															setCurCard(cur)
														}}
													>
														<div
															className='card'
															style={{
																borderRadius:
																	'10px',
															}}
														>
															<div
																className='card-body card-head-title'
																style={{
																	paddingBottom: 0,
																}}
															>
																<p
																	className='product-title'
																	style={{
																		marginBottom:
																			'-5px',
																	}}
																>
																	{cur.name}
																</p>
																<p className='product-description'>
																	{
																		cur.bundle_id
																	}
																</p>
															</div>
															<div
																className='cards-wrapper'
																style={{
																	alignItems:
																		'center',
																	margin: '0 auto',
																}}
															>
																<div
																	className='card-body'
																	style={{
																		maxWidth: 180,
																		maxHeight: 180,
																		padding: 10,
																	}}
																>
																	<img
																		alt='card'
																		className='product-img-outer'
																		src={
																			cur.image
																		}
																		style={{
																			width: '100%',
																			borderRadius:
																				'10px',
																			minWidth: 100,
																		}}
																	/>
																</div>
																<div
																	className='card-body'
																	style={{
																		display:
																			'flex',
																		flexDirection:
																			'column',
																	}}
																>
																	<p>
																		Version:{' '}
																		{
																			cur.version
																		}{' '}
																		(
																		{
																			cur.bundle_version
																		}
																		)
																	</p>
																	<p>
																		{
																			cur.created_at
																		}
																	</p>
																	<p>
																		Type:{' '}
																		{
																			cur.type
																		}
																	</p>
																</div>
															</div>
															<div className='btn-container'>
																<a
																	href={
																		cur.download
																	}
																	className='download-btn card-btn'
																	style={{
																		textDecoration:
																			'none',
																		fontSize: 12,
																		border: '1px solid #1890ff',
																		textAlign:
																			'center',
																		padding: 5,
																		maxWidth: 70,
																		borderRadius: 3,
																		marginBottom: 20,
																	}}
																	onClick={(
																		e
																	) =>
																		e.stopPropagation()
																	}
																	target='_blank'
																	rel='noreferrer'
																>
																	<img
																		src={
																			download
																		}
																		alt='donwload icon'
																	/>
																</a>
																<button
																	onClick={(
																		e
																	) => {
																		setCurCard(
																			cur
																		)
																		e.preventDefault()
																		e.stopPropagation()
																		setShow(
																			(
																				show
																			) =>
																				!show
																		)
																	}}
																	className='download-btn card-btn'
																>
																	<img
																		src={qr}
																		alt='qr icon'
																	/>
																</button>
																{session.length !==
																	0 &&
																Number(
																	cur.space_id
																) ===
																	Number(
																		spaces
																	) &&
																currentIsAdmin ===
																	1 ? (
																	<button
																		className='card-btn delete-btn'
																		onClick={(
																			e
																		) => {
																			e.stopPropagation()

																			dispatch(
																				actions.deleteBuild.getDeleteBuild.init(
																					cur.build_id
																				)
																			)
																		}}
																	>
																		<img
																			src={
																				bin
																			}
																			alt='trash icon'
																		/>
																	</button>
																) : (
																	''
																)}
															</div>
														</div>
													</div>
												))}
											</>
										) : (
											<>
												{Object.values(
													currentCard.builds
												).map((cur) =>
													selectValue !==
													cur.version ? (
														''
													) : (
														<div
															className='col-lg-3 col-md-4 col-sm-6 col-12 product-item stretch-card'
															style={{
																display: 'flex',
																flexWrap:
																	'wrap',
																cursor: 'pointer',
															}}
															key={cur.build_id}
															onClick={(e) => {
																e.preventDefault()
																e.stopPropagation()
																setShowCardModal(
																	(
																		showCardModal
																	) =>
																		!showCardModal
																)
																setCurCard(cur)
															}}
														>
															<div className='card'>
																<div className='card-body card-head-title'>
																	<p className='product-title'>
																		{
																			cur.name
																		}
																	</p>
																	<p>
																		{
																			cur.bundle_id
																		}
																	</p>
																</div>
																<div className='cards-wrapper'>
																	<div className='card-body'>
																		<img
																			alt='card'
																			className='product-img-outer'
																			src={
																				cur.image
																			}
																			style={{
																				maxWidth: 150,
																				maxHeight: 150,
																			}}
																		/>
																	</div>
																	<div
																		className='card-body'
																		style={{
																			display:
																				'flex',
																			flexDirection:
																				'column',
																		}}
																	>
																		<p>
																			Version:{' '}
																			{
																				cur.version
																			}{' '}
																			(
																			{
																				cur.bundle_version
																			}
																			)
																		</p>
																		<p>
																			{
																				cur.created_at
																			}
																		</p>
																		<div className='btn-container'>
																			<a
																				href={
																					cur.download
																				}
																				className='download-btn'
																				style={{
																					textDecoration:
																						'none',
																					fontSize: 12,
																					border: '1px solid #1890ff',
																					textAlign:
																						'center',
																					padding: 5,
																					maxWidth: 70,
																					borderRadius: 3,
																				}}
																				onClick={(
																					e
																				) =>
																					e.stopPropagation()
																				}
																				target='_blank'
																				rel='noreferrer'
																			>
																				Download
																			</a>
																			<button
																				onClick={(
																					e
																				) => {
																					setCurCard(
																						cur
																					)
																					e.preventDefault()
																					e.stopPropagation()
																					setShow(
																						(
																							show
																						) =>
																							!show
																					)
																				}}
																				className='download-btn'
																			></button>
																			{session.length !==
																				0 &&
																			Number(
																				cur.space_id
																			) ===
																				Number(
																					spaces
																				) &&
																			currentIsAdmin ===
																				1 ? (
																				<button
																					className='delete-btn'
																					onClick={(
																						e
																					) => {
																						e.stopPropagation()

																						dispatch(
																							actions.deleteBuild.getDeleteBuild.init(
																								cur.build_id
																							)
																						)
																					}}
																				>
																					<img
																						src={
																							bin
																						}
																						alt='trash icon'
																					/>
																				</button>
																			) : (
																				''
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												)}
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
