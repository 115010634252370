export const initialState = {
    loading: false,
    counter: 0,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UI/LOADING': return {
            ...state,
            loading: action.payload,
        };
        case 'UI/INC': return {
            ...state,
            counter: state.counter + 1
        };
        case 'UI/DEC': return {
            ...state,
            counter: state.counter - 1
        };
        default:
            return state;
    }
}
