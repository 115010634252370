import {takeEvery} from 'redux-saga/effects';
import {openNotification} from "./utils"
import {actions} from 'store';
import {api} from 'store/api';
import {bindApiCall} from "./utils";




function* login(action) {
    try {
        const result = yield bindApiCall(actions.auth.login, api.login, action.payload);
        if(result) {
            localStorage.setItem('token', result.data.token);
        }
    }
    catch (e) {
        openNotification('error', "Bad login or password", "Error")
    }

}

export function* loginSaga() {
    yield takeEvery('AUTH/LOGIN_INIT', login);
}

function* logout() {
    console.log('LOGOUT!');
   yield localStorage.removeItem('token')
}

export function* logoutSaga() {
    yield takeEvery('AUTH/LOGOUT', logout);
}
