import { createSelector } from "reselect";

export const state = (rootState) => rootState.deleteBuild;

export const main_page_redirect = createSelector(
	[state],
	(deleteBuild) => deleteBuild.main_page_redirect
);

export const deleteLoading = createSelector(
	[state],
	(deleteBuild) => deleteBuild.deleteLoading
);

export const finishedLoad = createSelector(
	[state],
	(deleteBuild) => deleteBuild.finishedLoad
);
