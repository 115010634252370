import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import { createStructuredSelector } from 'reselect'
import { useCallback, useEffect } from 'react'
import { Spin } from 'antd'
import GoBack from './GoBack'

const rootSelector = createStructuredSelector({
	spaces: selectors.session.spaces,
	isInitialized: selectors.session.isInitialized,
	notify: selectors.auth.notify,
})
export const Notify = () => {
	const { spaces, isInitialized, notify } = useSelector(rootSelector)

	const dispatch = useDispatch()

	const userSwitcherHandler = (e) => {
		let notify
		e.target.checked === true ? (notify = 1) : (notify = 0)

		dispatch(actions.notify.userChange.init(notify))
	}

	const spaceSwitcherHandler = (e) => {
		let notify
		e.target.checked === true ? (notify = 1) : (notify = 0)
		let space_id = e.target.closest('.parent').id

		let formData = new FormData()

		formData.append('notify', notify)
		formData.append('space_id', space_id)

		dispatch(actions.notify.spaceChange.init(formData))
	}

	const setSwitcherInputs = useCallback(() => {
		const userSwitcher = document.querySelector('.user-switcher')
		const spaceSwitcher = document.querySelectorAll('.space-switcher')

		userSwitcher.checked = notify === 1 ? true : false

		spaces.forEach((space, index) => {
			spaceSwitcher[index].checked = space.notify === 1 ? true : false
		})
	}, [notify, spaces])

	useEffect(() => {
		if (isInitialized) {
			setSwitcherInputs()
		}
	}, [isInitialized, setSwitcherInputs])

	return (
		<>
			{!isInitialized ? (
				<Spin />
			) : (
				<>
					<GoBack />
					<div className='row color-secondary'>
						<div className='col-lg-12'>
							<div className='col-lg-12 text-center mb-3'>
								<h3 className='color-secondary'>
									Manage your personal notifications
								</h3>
							</div>
							<div className='col-lg-12 text-center d-flex align-items-center'>
								<div className='col-lg-6'>
									<h4 className='color-secondary'>
										Switcher for all spaces
									</h4>
								</div>
								<div className='col-lg-6 custom-control custom-switch'>
									<input
										type='checkbox'
										className='custom-control-input user-switcher'
										id='customSwitchAll'
										onChange={userSwitcherHandler}
									/>
									<label
										style={{ marginLeft: '90px' }}
										className='custom-control-label'
										htmlFor='customSwitchAll'
									></label>
								</div>
							</div>
							<hr className='h-line' />

							{spaces.map((space) => {
								return (
									<div
										key={space.id}
										id={space.id}
										className='parent col-lg-12 d-flex justify-content-center text-center mb-1'
									>
										<div className='col-6'>
											<h5 className='color-secondary'>
												{space.name}
											</h5>
										</div>
										<div className='col-lg-6 custom-control custom-switch d-flex justify-content-center'>
											<input
												type='checkbox'
												className='custom-control-input space-switcher'
												id={`customSwitch${space.id}`}
												onChange={spaceSwitcherHandler}
											/>
											<label
												style={{ marginLeft: '90px' }}
												className='custom-control-label'
												htmlFor={`customSwitch${space.id}`}
											></label>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</>
			)}
		</>
	)
}
