export const createAsyncAction = (type) => ({
    init: (payload) => ({
        type: `${type}_INIT`,
        payload,
    }),
    done: (payload) => ({
        type: `${type}_DONE`,
        payload,
    }),
    fail: (error) => ({
        type: `${type}_FAIL`,
        error,
    }),
});
