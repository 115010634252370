import React, { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../store'
import { openNotification } from '../../store/sagas/utils'
import { Form } from 'react-bootstrap'
import GoBack from './GoBack'

const rootSelector = createStructuredSelector({
	loading: selectors.cards.loading,
	types: selectors.session.types,
	spaces: selectors.session.spaces,
	isInitialized: selectors.session.isInitialized,
})

export const FileUpload = ({ selectedTypes }) => {
	const [selectedFile, setSelectedFile] = useState()
	const [isFileUploaded, setIsFileUploaded] = useState(true)
	const [typeSelectValue, setTypeSelectValue] = useState('')
	const [spaceSelectValue, setSpaceSelectValue] = useState('')

	const typeRef = useRef()
	const spaceRef = useRef()
	const textAreaRef = useRef()

	const { loading, types, spaces, isInitialized } = useSelector(rootSelector)

	const dispatch = useDispatch()

	const responseTextField = document.getElementById('response-text')

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0])
	}

	const typesSelectHandler = (e) => {
		const currentType = e.target.value
		setTypeSelectValue(currentType)
	}

	const spacesSelectHandler = (e) => {
		const currentSpace = e.target.value
		setSpaceSelectValue(currentSpace)
	}

	const handleSubmission = (e) => {
		e.preventDefault()

		if (!localStorage.getItem('token')) {
			dispatch(actions.auth.logout())
		}
		responseTextField.innerHTML = ''

		localStorage.setItem('type', typeSelectValue)
		localStorage.setItem('space', spaceSelectValue)

		setIsFileUploaded(false)
		const formData = new FormData()

		formData.append('ipa', selectedFile)
		formData.append('type', typeSelectValue)
		formData.append('space_id', spaceSelectValue)
		formData.append('comment', textAreaRef.current.value)

		fetch('https://ipa3.testdlab.com/api/v1/upload/file', {
			method: 'POST',
			body: formData,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.status !== 200) {
					openNotification(
						'error',
						result.error.ipa ||
							result.error.type ||
							result.error.token ||
							'Incorrect file',
						'Error'
					)
					responseTextField.innerHTML = `
                    <p style="font-size: 20px; margin-bottom: 20px">${
						result.error.ipa ||
						result.error.token ||
						'Incorrect file.'
					}</p>
                    <a style="font-size: 20px; border: 1px solid #248afd; padding: 5px 10px; text-decoration: none" href="${
						window.location.protocol
					}//${window.location.host}/${result.data.space_id}/${
						result.data.app_id
					}">Go to file</a>  
                    `
					responseTextField.style.color = '#ff4747'
				}
				setIsFileUploaded(true)
				if (result.status === 200) {
					openNotification('success', 'File uploaded!', 'Success')
					responseTextField.innerHTML = `
                        <p style="font-size: 20px; margin-bottom: 20px">File uploaded!</p>
                        <a style="font-size: 20px; border: 1px solid #248afd; padding: 5px 10px; text-decoration: none" href="${window.location.protocol}//${window.location.host}/${result.data.app.spaces_id}/${result.data.app.id}">Go to file</a>
                        `

					responseTextField.style.color = '#82dd55'
				}
			})
			.catch((error) => {
				openNotification(
					'error',
					`
                Something went wrong...   Error status: 
                ${error}`,
					'Error'
				)
				responseTextField.innerHTML = `Something went wrong... Error status: ${error}`
				responseTextField.style.color = '#ff4747'
				setIsFileUploaded(true)
			})
	}

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			dispatch(actions.auth.logout())
		}
		if (isInitialized) {
			const currType = typeRef.current.options[0].value
			let currSpace = spaceRef.current.options[0].value

			setSpaceSelectValue(currSpace)
			setTypeSelectValue(currType)
		}
		if (localStorage.getItem('space') && localStorage.getItem('type')) {
			typeRef.current.value = localStorage.getItem('type')
			spaceRef.current.value = localStorage.getItem('space')
			setSpaceSelectValue(localStorage.getItem('space'))
			setTypeSelectValue(localStorage.getItem('type'))
		}
	}, [setSpaceSelectValue, setTypeSelectValue, isInitialized, dispatch])

	return (
		<>
			<GoBack />
			{isFileUploaded === false ? <Spin /> : ''}
			{loading ? (
				<Spin />
			) : (
				<div className='row'>
					<div className='col-md-6 grid-margin stretch-card'>
						<div className='card'>
							<div className='card-body'>
								<form
									method='POST'
									encType='multipart/form-data'
									onSubmit={handleSubmission}
								>
									<Form.Group>
										<label style={{ color: '#b1b1b5' }}>
											File upload
										</label>
										<div className='custom-file'>
											<Form.Control
												type='file'
												className='form-control visibility-hidden'
												required='required'
												id='customFileLang'
												lang='es'
												onChange={changeHandler}
											/>
											<label
												className='custom-file-label'
												htmlFor='customFileLang'
											>
												Upload your file
											</label>
										</div>
									</Form.Group>
									<Form.Group>
										<label
											htmlFor='exampleFormControlSelect1'
											style={{ color: '#b1b1b5' }}
										>
											Space select
										</label>
										<select
											ref={spaceRef}
											onChange={spacesSelectHandler}
											className='form-control form-control-lg'
											id='exampleFormControlSelect1 spaces-select'
										>
											{spaces.map((space) => (
												<option
													key={space.id}
													value={space.id}
													{...space}
												>
													{space.name}
												</option>
											))}
										</select>
									</Form.Group>
									<Form.Group>
										<label
											htmlFor='exampleFormControlSelect1'
											style={{ color: '#b1b1b5' }}
										>
											Type select
										</label>
										<select
											ref={typeRef}
											onChange={typesSelectHandler}
											className='form-control form-control-lg'
											id='exampleFormControlSelect1 types-select'
										>
											{Object.values(types).map(
												(type, index) => (
													<option
														key={index}
														value={type}
													>
														{type}
													</option>
												)
											)}
										</select>
									</Form.Group>
									<Form.Group>
										<label
											htmlFor='exampleTextarea1'
											style={{ color: '#b1b1b5' }}
										>
											Comment
										</label>
										<textarea
											ref={textAreaRef}
											className='form-control'
											id='exampleTextarea1 textarea-field'
											rows='6'
										/>
									</Form.Group>
									<div
										id='response-text'
										style={{
											fontSize: 24,
											marginBottom: 30,
										}}
									/>
									<button
										type='submit'
										className='btn btn-primary mr-2'
									>
										Upload
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
