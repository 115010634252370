import { call, put } from 'redux-saga/effects'
import { notification } from 'antd'

export function* bindApiCall(asyncAction, apiCall, ...args) {
	try {
		const { data } = yield call(apiCall, ...args)
		yield put(asyncAction.done(data))
		return data
	} catch (e) {
		yield put(asyncAction.fail(e))
		throw new Error(e.message)
	}
}

export const openNotification = (type, description, message) => {
	notification[type](
		{
			message: message,
			description: description,
			style: {
				marginTop: 100,
			},
		},
		3
	)
}
