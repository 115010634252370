export const initialState = {
    currentCard: null,
    items: [],
    loading: false,
    error: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CARDS/GET_CARDS_INIT':
            return {
                ...state,
                error: null,
                loading: true,
            };
        case 'CARDS/GET_CARDS_DONE':
            return {
                ...state,
                loading: false,
                items: action.payload.data,
            };
        case 'CARDS/GET_CARDS_FAIL':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case 'CARDS/GET_BY_ID_INIT':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'CARDS/GET_BY_ID_DONE':
            return {
                ...state,
                loading: false,
                currentCard: action.payload.data,
            };
        case 'CARDS/GET_BY_ID_FAIL':
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
