export const initialState = {
	build_id: null,
	deleteLoading: null,
	error: null,
	main_page_redirect: null,
	finishedLoad: false,
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case "CARDS/GET_DELETE_INIT":
			return {
				...state,
				error: null,
				deleteLoading: true,
			};
		case "CARDS/GET_DELETE_DONE":
			return {
				...state,
				deleteLoading: false,
				finishedLoad: true,
			};
		case "CARDS/GET_DELETE_FAIL":
			return {
				...state,
				deleteLoading: false,
				error: action.error,
				finishedLoad: true,
			};
		default:
			return state;
	}
};
