import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { actions } from 'store'
import { Layout } from 'components'
import { HomePage } from './HomePage'
import { SpacePage } from './SpacePage'
import { CardPage } from './CardPage'
import { FileUpload } from '../../components/ui/FileUpload'
import { Notify } from 'components/ui/Notify'

export const HomeStack = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(actions.session.initApp.init())
	}, [dispatch])
	return (
		<Layout>
			<Switch>
				<Route exact path='/upload' component={FileUpload} />
				<Route exact path='/notify' component={Notify} />
				<Route
					exact
					path='/:spaces/:cardId/:version'
					component={CardPage}
				/>
				<Route exact path='/:spaces/:cardId' component={CardPage} />
				<Route exact path='/:spaces' component={SpacePage} />
				<Route exact path='/' component={SpacePage} />
				<Route exact path='/' component={HomePage} />
				<Route render={() => <Redirect to='/' />} />
			</Switch>
		</Layout>
	)
}
