import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { history } from 'utils/history'
import { swDev } from './swDev'
import './App.scss'
import './App'
import 'antd/dist/antd.css'

var isSafari =
	/constructor/i.test(window.HTMLElement) ||
	(function (p) {
		return p.toString() === '[object SafariRemoteNotification]'
	})(
		!window['safari'] ||
			(typeof safari !== 'undefined' && window.safari.pushNotification)
	)

var isiPad = navigator.userAgent.match(/iPad/i) != null
var isiPhone = navigator.userAgent.match(/iPhone/i) != null
var isiPod = navigator.userAgent.match(/iPod/i) != null

if (!isSafari && !isiPad && !isiPhone && !isiPod) {
	swDev()
}

ReactDOM.render(
	<React.StrictMode>
		<Router history={history}>
			<Provider store={store}>
				<App />
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
